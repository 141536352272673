/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";
import { Container, Box, Flex, NavLink } from "@theme-ui/components";
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";
import { Grid, Divider, Button, Card, Text } from "@theme-ui/components";
import NavBar from "../components/NavBar";
import { navigate } from "gatsby-link";
import Seo from "../components/seo";
import {
  Label,
  Input,
  Select,
  Textarea,
  Radio,
  Checkbox,
  Slider,
} from "theme-ui";
import Footer from "../components/footer";
import { login, logout, isAuthenticated, getProfile } from "../utils/auth";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default function Contact() {
  const [state, setState] = React.useState({});

  const user = getProfile();

  console.log(user);

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => alert(error));
  };
  return (
    <div>
      <Seo />
      <NavBar />
      <Container
        sx={{
          minHeight: "85vh",
        }}
      >
        <Grid p={2} columns={[1, 1, 2]}>
          <Box py={3}>
            <Text py={3} as="h1">
              Contact AME Systems
            </Text>
            <Text py={3} as="h2">
              Become a Contributing Mentor on AME Systems
            </Text>
            <Text
              py={3}
              as="p"
              sx={{
                paddingRight: "50px",
              }}
            >
              Interested in sharing what you know? We are always on the lookout
              for content contributors so if you're a white haired guru looking
              to mentor tomorrow's technicians, "contact us" and let's have a
              discussion.
            </Text>
            <Text
              py={3}
              as="p"
              sx={{
                paddingRight: "50px",
              }}
            >
              If you experience any difficulty with the website, please contact
              us, or if you are intereseted in becoming a registered user,
              please contact us.
            </Text>
          </Box>
          <Box
            py={4}
            as="form"
            name="contact"
            method="post"
            action="/thanks/"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={handleSubmit}
          >
            <Label htmlFor="username">Full Name</Label>
            <Input
              name="name"
              id="name"
              mb={3}
              onChange={handleChange}
              required
            />
            <Label htmlFor="username">eMail</Label>
            <Input
              type="email"
              name="email"
              id="email"
              mb={3}
              onChange={handleChange}
              required
            />

            <Label htmlFor="comment">Question/Comment</Label>
            <Textarea
              name="comment"
              id="comment"
              rows={6}
              mb={3}
              onChange={handleChange}
              required
            />

            <Button as="button" type="submit">
              Submit
            </Button>
          </Box>
        </Grid>
      </Container>
      <Footer />
    </div>
  );
}
